

























































import StartEnd from '@/enums/StartEnd'
import { computed, defineComponent, PropType, ref, unref } from '@nuxtjs/composition-api'
import dayjs, { Dayjs } from 'dayjs'
import minMax from 'dayjs/plugin/minMax'

import useDayjsLocale from '@/composables/use-dayjs-locale'
import useI18n from '@/composables/use-i18n'

import { getThisMonth } from '@/modules/calendar'
import DateHour from '@/classes/DateHour'

import GDialogBody from '@/components/GDialogBody.vue'

dayjs.extend(minMax)

export default defineComponent({
  props: {
    start: {
      type: Dayjs as PropType<Dayjs | null>,
      default: null,
    },
    end: {
      type: Dayjs as PropType<Dayjs | null>,
      default: null,
    },
    isSaveDisabled: {
      type: Boolean,
      default: true,
    },
    editingTarget: {
      type: Number as PropType<StartEnd | null>,
      default: null,
    },
    dual: {
      type: Boolean,
      default: false,
    },
    disallowedDates: {
      type: [Array, Function, null] as PropType<Dayjs[] | ((date: Dayjs) => boolean) | null>, // 若 callback 回傳為 true, 代表 date 為 disallowed
      default: null,
    },
    allowBeforeToday: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const i18n = useI18n()
    const current = ref(getThisMonth()) // [desktop] 本頁的年月
    const { getCalendarTitle } = useDayjsLocale()

    const getNextMonth = (month: Dayjs, n = 1) => month.add(n, 'month')
    // 翻頁
    const next = (n = 1) => {
      current.value = getNextMonth(unref(current), n)
    }

    const goTo = (day: Dayjs = getThisMonth()) => {
      // [desktop] 翻至某日所在月份
      current.value = day.startOf('month')

      // [mobile] 滾動至該月份處
      const target = dialogBodyItems.value
        .find((_, index) => current.value.isSame(getNextMonth(today, index)))
      target?.scrollIntoView({ inline: 'start' })
    }

    const select = (date: DateHour) => {
      emit('select', date)
    }

    const save = () => {
      emit('save')
    }
    const reset = () => {
      emit('reset')
      goTo()
    }

    const disabledBefore = DateHour.getMinDateHour().date

    const header = computed(() => [
      {
        label: i18n.t('field.startDate.label'),
        type: StartEnd.START,
        value: props.start ? props.start?.format('MM-DD') : i18n.t('field.date.placeholder'),
      },
      {
        label: i18n.t('field.endDate.label'),
        type: StartEnd.END,
        value: props.end ? props.end?.format('MM-DD') : i18n.t('field.date.placeholder'),
      },
    ])

    const today = dayjs().startOf('month')

    const dialogBody = ref<InstanceType<typeof GDialogBody> | null>(null)
    const dialogBodyItems = ref<Element[]>([])

    return {
      current,
      getNextMonth,
      getCalendarTitle,
      next,
      goTo,
      select,
      save,
      reset,
      disabledBefore,
      header,
      today,
      dialogBody,
      dialogBodyItems,
    }
  },
})
